<template>
  <VArrowBtn
    v-bind="props"
    :href="href"
  >
    {{ cta }}
  </VArrowBtn>
</template>

<script lang="ts">
import { isEmpty, omit } from "lodash";
import { defineComponent } from "vue";
import { VBtn } from "vuetify/components";

export default defineComponent({
  name: "GetStartedBtn",
  extends: VBtn,
  props: {
    color: { type: String, default: "primary" },
    cta: { type: String, default: "Get started" },
    email: { type: String },
    plan: { type: String },
    period: { type: String },
  },
  setup(props) {
    const config = useRuntimeConfig();
    const router = useRouter();

    const href = computed(() => {
      const query: any = {};
      if (props.email) query.email = props.email;
      if (props.plan) query.plan = props.plan;
      if (props.period) query.period = props.period;

      if (!isEmpty(query)) {
        query.trial = "";

        const route = router.resolve({
          name: "register",
          query,
        });

        return route.href;
      }

      return getUrl(config.public.links.dashboard, "register");
    });

    return {
      href,
    };
  },
  computed: {
    props() {
      return omit(this.$props, ["cta", "email", "plan", "period"]);
    },
  },
});
</script>
